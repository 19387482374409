import React from 'react'

import Box from '@mui/material/Box'

import LoginForm from '@components/auth/LoginForm'
import { Content, PageWrapper } from '@shared'

const LoginPage = () => {
  return (
    <Box display="flex" flexDirection="column">
      <PageWrapper headerTitle="Login">
        <Content>
          <LoginForm />
        </Content>
      </PageWrapper>
    </Box>
  )
}

LoginPage.withoutLayout = true

export default LoginPage
