import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useAuth0 } from '@auth0/auth0-react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { Loading, MessageContainer } from '@shared'

const LoginForm: React.FunctionComponent = () => {
  const [verifyEmail, setVerifyEmail] = useState(false)
  const [accessDenied, setAccesDenied] = useState(false)
  const { loginWithPopup, error, isAuthenticated, isLoading } = useAuth0()
  const { replace } = useRouter()

  useEffect(() => {
    setVerifyEmail(
      error?.message === 'Please verify your email before logging in.'
    )
    setAccesDenied(error?.message === 'Access denied.')
  }, [error])

  useEffect(() => {
    if (isAuthenticated) {
      replace('/')
    }
  }, [isAuthenticated, replace])

  const handleLogin = async () => {
    try {
      await loginWithPopup({ prompt: 'login' })
    } catch (e) {}
  }

  const handleSignup = async () => {
    try {
      await loginWithPopup({ prompt: 'login', screen_hint: 'signup' })
    } catch (e) {}
  }

  if (isLoading || isAuthenticated) {
    return <Loading />
  }

  return (
    <MessageContainer
      title={
        verifyEmail
          ? 'Please verify your email, then login'
          : accessDenied
          ? 'Your account can`t access Mojito Mint'
          : 'It’s Mojito time!'
      }
    >
      <Box>
        <Button
          variant="containedDarkGrey"
          sx={{ padding: '13px 50px', mr: 2 }}
          onClick={handleLogin}
        >
          Log In
        </Button>
        {!verifyEmail && (
          <Button
            variant="containedWhite"
            sx={{ padding: '13px 50px' }}
            onClick={handleSignup}
          >
            Sign Up
          </Button>
        )}
      </Box>
    </MessageContainer>
  )
}

export default LoginForm
